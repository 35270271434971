<template>

  <validation-observer ref="simpleRules">
    <b-form
      @submit.prevent="postSliderData"
      @reset.prevent="OnReset"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Title *"
            label-for="title"
            label-cols-md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="title"
            >
              <b-form-input
                id="title"
                v-model="editData.title"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Image *"
            label-for="image"
            label-cols-md="4"
          >
            <b-form-file
              id="image"
              v-model="editData.image"
              accept="image/*"
              type="file"
              @change="onFileChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Active"
            label-for="active"
            label-cols-md="4"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="editData.active"
                name="some-radios"
                value="1"
              >
                Active
              </b-form-radio>
              <b-form-radio
                v-model="editData.active"
                name="some-radios"
                value="0"
              >
                Non Active
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormRadio, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormFile,
} from 'bootstrap-vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref } from '@vue/composition-api'
// import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    BRow,
    BFormRadio,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    // vSelect,
    // quillEditor,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      content: [],
      categoryData: [1, 2, 3],
      editData: {},
      required,
      userData: {},
    }
  },
  mounted() {
    this.getUser()
    this.getSliderData()
  },
  methods: {
    onFileChanged(event) {
      // eslint-disable-next-line prefer-destructuring
      this.editData.image = event.target.files[0]
    },
    getSliderData() {
      axios
        .get(`/sliders/${this.$route.params.id}`)
        .then(res => {
          this.editData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    getUser() {
      const userLocalStorage = localStorage.getItem('user')
      const userDecoded = Buffer.from(userLocalStorage, 'base64').toString('utf-8')
      const userParsed = JSON.parse(userDecoded)
      this.userData = userParsed
    },
    postSliderData() {
      this.$refs.simpleRules.validate().then(success => {
        const postData = new FormData()
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.editData)) {
          postData.append(key, value)
        }

        // eslint-disable-next-line no-restricted-syntax
        // for (const pair of postData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }
        postData.append('created_by', this.userData.id === undefined ? '' : this.userData.id)
        postData.append('category_id', 1)
        postData.delete('category')

        if (success) {
          this.$swal({
            title: 'Please Wait..',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          }).then(
            axios.post(`/sliders/${this.$route.params.id}`, postData).then(res => {
              this.edit = {}
              // eslint-disable-next-line no-console
              console.log('res', res)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Your data has been saved.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                this.$router.push({ name: 'slider-all' })
              })
            }),
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.2rem;
    margin-bottom: 2px;
    padding-bottom: 10px;
    height: 150px;
}

.ql-container{
  min-height: inherit;
}
</style>
